.Service-box {
  background: var(--white);
  padding-block: 100px;
}

.service-card {
  border: 2px solid #f1f1f1;
  padding: 30px 20px;
  height: 100%;
  transition: all 0.5s;
}

.service-card:hover {
  border-color: var(--primaryLight);
  border-bottom-color: var(--primary);
  box-shadow: 0px 4px 0px var(--primary), 0px 20px 30px rgba(22, 34, 19, 0.1);
}

.service-card .service-icon {
  width: 70px;
  height: 70px;
  border: 2px solid #f1f1f1;
  border-radius: 70px;
  text-align: center;
  line-height: 70px;
}

.service-card:hover .service-icon {
  border-color: var(--primaryLight);
}

.service-card .service-icon svg {
  width: 40%;
  height: 40%;
}

.service-card .service-title {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-block: 14px;
  cursor: default;
}

.service-card .service-para {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondarytext);
  text-align: justify;
  cursor: default;
  margin: 0px;
}

@media (max-width: 1100px) {
  .service-card {
    padding: 20px 10px;
  }
}

@media (max-width: 800px) {
  .Service-box {
    padding-block: 50px;
  }
}

@media (max-width: 600px) {
  .Service-box .row {
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: auto;
  }
  .Service-box .row::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  }
  .Service-box .row > div {
    width: 90%;
  }
  .service-card {
    padding: 20px 10px;
    height: 100%;
    margin-bottom: 0px;
  }
  .service-card .service-para {
    margin: 0px;
    font-size: 12px;
  }
}
