.Expskill-box {
  background: var(--whiteLight);
  padding-block: 100px;
}

.skill-box {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.skill-box .skill-item {
  background: var(--white);
  border-radius: 200px;
  width: 150px;
  text-align: center;
  padding-block: 56px;
  box-shadow: 0px 2px 3px rgba(22, 34, 19, 0.1);
}

.skill-box .skill-item img {
  width: auto;
  height: 60px;
  margin-bottom: 20px;
}

.skill-box .skill-item p {
  font-size: 20px;
  margin: 0px;
  font-family: "Oswald";
  font-weight: 400;
  color: var(--secondarytext);
  cursor: default;
}

.skill-box .skill-item:nth-child(2),
.skill-box .skill-item:nth-child(5) {
  animation-delay: 0.2s;
}

.skill-box .skill-item:nth-child(3),
.skill-box .skill-item:nth-child(6) {
  animation-delay: 0.4s;
}

.exp-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 20px;
}

.exp-box .exp-item {
  background: var(--white);
  padding: 50px 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  box-shadow: 0px 1px 3px rgba(22, 34, 19, 0.1), 0px 6px 0px #ededed;
  transition: all 0.5s;
}

.exp-box .exp-item:hover {
  box-shadow: 0px 4px 0px var(--primary), 0px 20px 30px rgba(22, 34, 19, 0.1);
}

.exp-box .exp-item .exp-img {
  height: 60px;
  width: 60px;
  flex: 0 0 60px;
  text-align: center;
}

.exp-box .exp-item .exp-img img {
  height: 100%;
  width: auto;
}

.exp-box .exp-item .exp-title {
  margin-bottom: 6px;
}

.exp-box .exp-item .exp-title h1 {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
  color: var(--darkText);
  margin: 0px;
}

.exp-box .exp-item .exp-para p {
  font-weight: 400;
  font-size: 15px;
  color: var(--secondarytext);
  margin: 0px;
}

@media (max-width: 1100px) {
  .exp-box .exp-item .exp-title h1 {
    font-size: 16px;
  }
}

@media (max-width: 800px) {
  .Expskill-box {
    padding-block: 50px;
  }

  .skill-box {
    gap: 14px;
    margin-bottom: 30px;
  }

  .skill-box .skill-item {
    width: 104px;
    padding-block: 34px;
  }
}

@media (max-width: 600px) {
  .skill-box {
    gap: 10px;
  }
  .skill-box .skill-item {
    width: 90px;
    padding-block: 30px;
  }
  .skill-box .skill-item p {
    font-size: 16px;
  }
  .skill-box .skill-item img {
    width: auto;
    height: 40px;
    margin-bottom: 10px;
  }
  .exp-box .exp-item {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 15px;
  }
  .exp-box .exp-item .exp-para {
    flex-direction: column;
  }
  .exp-box .exp-item .exp-para p {
    font-size: 12px;
  }
}
