.header-box {
  background: var(--white);
  padding-inline: 55px;
}

.header-box .nav-link {
  color: var(--darkgreen) !important;
}

@media (max-width: 600px) {
  .header-box {
    padding-inline: 10px;
  }

  .header-box .navbar-brand {
    margin-right: 0px;
  }

  .header-box .navbar-brand svg {
    width: 110px;
  }

  .header-box .navbar-nav {
    flex-direction: row;
    gap: 10px;
  }
}
