.About-box {
  background: var(--whiteLight);
  padding-block: 100px;
}

.About-box .about-left {
  animation-duration: 2s;
}

.About-box .about-left h2 {
  font-family: "Oswald";
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
}

.About-box .about-left h2 span {
  font-family: "Oswald";
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  font-weight: 600;
}

.About-box .about-left p {
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  text-align: justify;
  color: var(--secondarytext);
}

.about-contact {
  margin-top: 50px;
}

.about-contact h5 {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: var(--secondarytext);
  cursor: default;
}

.about-contact h4 {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: var(--darkText);
}

.about-right h2,
.about-right span {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 33px;
  margin-top: 10px;
  cursor: default;
}

.about-right span {
  font-weight: 500;
}

@media (max-width: 800px) {
  .About-box {
    padding-block: 50px;
  }
}

@media (max-width: 600px) {
  .About-box .about-left p {
    font-size: 12px;
    line-height: 20px;
  }
  .about-contact {
    margin-top: 20px;
  }
}
