.work-box {
  margin-block: 80px;
}

.project-card {
  margin-bottom: 20px;
}

.project-card .project-left {
  text-align: left;
  padding-left: 50px;
}

.project-card .project-left .chip-row {
  display: flex;
  gap: 10px;
  margin-block: 20px 10px;
}

.project-card .project-left .chip-row .chip-item {
  border: 1px solid rgba(3, 4, 28, 0.08);
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: var(--darkText);
  padding: 10px;
}

.project-card .project-left .project-title {
  font-family: "Oswald";
  font-weight: 600;
  font-size: 34px;
  line-height: 41px;
  color: var(--darkText);
}

.project-card .project-left .project-para {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--secondarytext);
  margin-bottom: 20px;
}

.project-card .project-right {
  text-align: right;
}

.project-card .project-right img {
  height: 450px;
  width: auto;
  max-width: 100%;
}

.project-card:nth-child(even) .row {
  flex-direction: row-reverse;
}

.project-card:nth-child(even) .project-right {
  text-align: left;
}

.project-card:nth-child(even) .project-left {
  padding-left: 0px;
}

@media (max-width: 1100px) {
  .project-card .project-right img {
    height: auto;
  }
}

@media (max-width: 600px) {
  .work-box {
    margin-block: 40px;
  }

  .project-card .project-left,
  .project-card:nth-child(even) .project-left {
    padding-left: 10px;
    padding-top: 20px;
  }
}
