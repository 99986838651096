:root {
  --darkgreen: #152e27;
  --secondarytext: #5e6167;
  --secondarytext2: #7e7e7e;
  --black: #000;
  --darkText: #03041c;
  --lightText: #eaeaea;
  --white: #ffffff;
  --whiteLight: #f5f5f5;
  --primary: #ff8a00;
  --primaryLight: #ffe8cc;
  --primaryLight2: #ffb966;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");

* {
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  font-size: 14px;
}

html {
  scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ddd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
}

a {
  color: inherit;
  text-decoration: none !important;
}

.btn-primary {
  background: var(--primary) !important;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  border-radius: 0px !important;
  padding: 12px 42px !important;
  font-family: "Oswald", sans-serif !important;
  font-size: 16px !important;
  transition: all 0.4s !important;
  color: var(--white) !important;
}

.btn-primary:hover {
  background-color: #d67302 !important;
  transform: scale(1.1);
}

.btn-primary:active {
  background-color: #d67302 !important;
  transform: scale(1);
}

.btn-light {
  background: var(--white) !important;
  background-color: var(--white) !important;
  border-color: var(--white) !important;
  border-radius: 0px !important;
  padding: 12px 42px !important;
  font-family: "Oswald", sans-serif !important;
  font-size: 16px !important;
  transition: all 0.4s !important;
  color: var(--darkText) !important;
}

.btn-light:hover {
  background-color: var(--whiteLight) !important;
  transform: scale(1.1);
}

.btn-light:active {
  background-color: var(--whiteLight) !important;
  transform: scale(1);
}

@media (max-width: 600px) {
  .btn-primary,
  .btn-light {
    padding: 8px 20px !important;
    font-size: 14px !important;
  }
  .btn-primary:hover {
    transform: scale(1);
  }
  .btn-light:hover {
    transform: scale(1);
  }
}
