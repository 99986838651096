/* Loader CSS */

.mainloader-container {
  background: #000;
  position: fixed;
  inset: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainloader-container svg {
  position: absolute;
}

.loader3 {
  width: 500px;
  height: 500px;
  display: inline-block;
  padding: 0px;
  text-align: left;
}

.loader3 span {
  position: absolute;
  display: inline-block;
  width: 500px;
  height: 500px;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.2);
  -webkit-animation: loader3 1.5s linear infinite;
  animation: loader3 1.5s linear infinite;
}

.loader3 span:last-child {
  animation-delay: -0.9s;
  -webkit-animation-delay: -0.9s;
}

@keyframes loader3 {
  0% {
    transform: scale(0, 0);
    opacity: 0.8;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@-webkit-keyframes loader3 {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.8;
  }

  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}

@media (max-width:600px) {

  .loader3,
  .loader3 span {
    width: 350px;
    height: 350px;
  }
}

/* Loader CSS */