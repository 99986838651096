.sidebar-navs {
  margin-bottom: 30px;
}

.sidebar-navs .nav-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-family: "Oswald";
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  color: var(--darkText);
  border-bottom: 1px solid #ddd;
  padding-block: 15px;
  color: var(--darkText) !important;
}

.sidebar-para {
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: var(--secondarytext);
  margin: 30px 0px 30px 0px;
}

.follow-box {
  border-top: 1px solid #ddd;
  padding-top: 14px;
}

.follow-box h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: var(--darkText);
}

.follow-box .follow-row {
  display: flex;
  gap: 10px;
}

.follow-box .follow-row a {
  width: 50px;
  height: 50px;
  text-align: center;
  border: 1px solid #ddd;
  line-height: 50px;
  color: var(--darkText);
}

.follow-box .follow-row a svg {
  height: 20px;
  width: auto;
}

.follow-box .follow-row a:hover {
  background: var(--primary);
  transform: rotate(360deg);
  transition: all 0.8s;
  color: var(--white);
}
