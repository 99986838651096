.banner-container {
  padding-inline: 55px;
}

.banner-contact {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.banner-contact .nav-link {
  transform: rotate(-90deg);
  width: 100%;
  white-space: nowrap;
}

.banner-box {
  background: var(--primaryLight);
  height: 100%;
  padding-top: 100px;
}

.banner-box .banner-left {
  padding-top: 100px;
}

.banner-box .banner-left h1 {
  font-family: "Oswald";
  font-weight: 600;
  font-size: 100px;
  text-transform: uppercase;
  color: var(--darkText);
  margin-bottom: 34px;
  cursor: default;
}

.banner-box .banner-left h5 {
  font-family: "Oswald";
  font-weight: 500;
  font-size: 28px;
  position: relative;
  color: var(--darkText);
  margin-bottom: 25px;
  display: inline-block;
  cursor: default;
}

.banner-box .banner-left h5::after {
  position: absolute;
  content: "";
  bottom: -6px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--primary);
}

.banner-box .banner-right {
  background: var(--primaryLight2);
  text-align: center;
  padding-inline: 10px;
  padding-top: 20px;
  height: 760px;
  animation-duration: 2s;
}

.banner-box .banner-right img {
  height: 100%;
  width: auto;
}

.banner-box .banner-animation1 {
  position: absolute;
  top: 16%;
  right: 10%;
  width: 40px;
  height: auto;
  -moz-animation: AMtranslateY 3s ease-in-out 1s forwards infinite alternate;
  -webkit-animation: AMtranslateY 3s ease-in-out 1s forwards infinite alternate;
  animation: AMtranslateY 3s ease-in-out 1s forwards infinite alternate;
}

.banner-box .banner-animation2 {
  position: absolute;
  top: 44%;
  right: 12%;
  width: 100px;
  height: auto;
  -moz-animation: AMrotate 3s ease-in-out 1s forwards infinite alternate;
  -webkit-animation: AMrotate 3s ease-in-out 1s forwards infinite alternate;
  animation: AMrotate 3s ease-in-out 1s forwards infinite alternate;
}

.banner-box .banner-animation3 {
  position: absolute;
  bottom: 15%;
  right: 10%;
  width: 16px;
  height: auto;
  -moz-animation: AMtranslateX 3s ease-in-out 1s forwards infinite alternate;
  -webkit-animation: AMtranslateX 3s ease-in-out 1s forwards infinite alternate;
  animation: AMtranslateX 3s ease-in-out 1s forwards infinite alternate;
}

@keyframes AMtranslateY {
  0% {
    -webkit-transform: translateY(-50px);
    -moz-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    -o-transform: translateY(-50px);
    transform: translateY(-50px);
  }

  100% {
    -webkit-transform: translateY(100px);
    -moz-transform: translateY(100px);
    -ms-transform: translateY(100px);
    -o-transform: translateY(100px);
    transform: translateY(100px);
  }
}

@keyframes AMrotate {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes AMtranslateX {
  0% {
    -webkit-transform: translateX(-40px);
    -moz-transform: translateX(-40px);
    -ms-transform: translateX(-40px);
    -o-transform: translateX(-40px);
    transform: translateX(-40px);
  }

  100% {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@media (max-width: 1100px) {
  .banner-box .banner-right {
    height: 480px;
  }
  .banner-box .banner-left h1 {
    font-size: 60px;
  }
  .banner-box .banner-animation1 {
    top: 10%;
    right: 5%;
  }
  .banner-box .banner-animation2 {
    top: 26%;
    right: 8%;
  }
  .banner-box .banner-animation3 {
    top: 37%;
    right: 7%;
  }
  .banner-box .banner-left {
    padding-top: 60px;
    padding-left: 20px;
  }
}

@media (max-width: 800px) {
  .banner-box {
    padding-top: 50px;
  }

  .banner-box .banner-right {
    height: 320px;
  }

  .banner-box .banner-left h1 {
    font-size: 50px;
    margin-bottom: 14px;
  }
  .banner-box .banner-animation1 {
    width: 40px;
    top: 6%;
  }
  .banner-box .banner-animation2 {
    width: 80px;
    top: 20%;
  }
  .banner-box .banner-animation3 {
    width: 14px;
    top: 32%;
  }
}

@media (max-width: 600px) {
  .banner-container {
    padding-inline: 0px;
  }
  .banner-container > .row {
    margin: 0px;
  }
  .banner-container > .row .col-md-11 {
    padding: 0px;
  }
  .banner-contact {
    display: none;
  }
  .banner-box {
    padding-block: 30px 40px;
  }
  .banner-box .banner-animation1,
  .banner-box .banner-animation2,
  .banner-box .banner-animation3 {
    display: none;
  }
  .banner-box .row {
    flex-direction: column-reverse;
  }

  .banner-box .banner-left {
    padding-top: 20px;
    padding-left: 0px;
    text-align: center;
  }
  .banner-box .banner-left h5 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .banner-box .banner-left h1 {
    font-size: 40px;
  }
  .banner-box .banner-left h1 br {
    display: none;
  }
}
