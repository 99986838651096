.TitleBar {
  text-align: center;
  margin-bottom: 50px;
}

.TitleBar .Title-container {
  position: relative;
}

.TitleBar .Title-container .Title-big {
  font-family: "Oswald";
  font-weight: 600;
  font-size: 110px;
  line-height: 110px;
  text-transform: uppercase;
  color: var(--lightText);
  cursor: default;
}

.TitleBar .Title-container .Title-small {
  font-family: "Oswald";
  font-weight: 600;
  font-size: 60px;
  line-height: 72px;
  text-transform: capitalize;
  color: var(--darkText);
  position: absolute;
  inset-inline: 0;
  bottom: 0px;
  margin: 0px;
  cursor: default;
}

.TitleBar .Title-para {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin: 0px;
  color: var(--secondarytext);
  cursor: default;
}

@media (max-width: 1100px) {
  .TitleBar .Title-container .Title-big {
    font-size: 105px;
  }
}

@media (max-width: 800px) {
  .TitleBar .Title-container .Title-big {
    font-size: 80px;
  }
}

@media (max-width: 600px) {
  .TitleBar .Title-container .Title-big {
    font-size: 34px;
    line-height: 40px;
  }
  .TitleBar .Title-container .Title-small {
    font-size: 25px;
    line-height: 30px;
  }
  .TitleBar {
    margin-bottom: 30px;
  }
  .TitleBar .Title-para {
    font-size: 12px;
    line-height: 16px;
  }
}
