.Hire-box {
  background: var(--primaryLight);
  padding-block: 150px 150px;
  position: relative;
  overflow: hidden;
}

.Hire-box .hire-container {
  position: relative;
}

.Hire-box .hire-container h1 {
  font-family: "Oswald";
  font-weight: 500;
  font-size: 60px;
  line-height: 63px;
  margin-bottom: 40px;
  cursor: default;
}

.Hire-box .hire-animation .hire-shape-1 {
  position: absolute;
  inset: 0%;
  margin: auto;
  width: 400px;
  height: auto;
}

.Hire-box .hire-animation .hire-shape-2 {
  position: absolute;
  left: 5%;
  top: 20%;
  width: 80px;
  height: auto;
  animation: tptranslateX 3s ease-in-out 1s forwards infinite alternate;
}

@keyframes tptranslateX {
  0% {
    -webkit-transform: translateX(-80px);
    -moz-transform: translateX(-80px);
    -ms-transform: translateX(-80px);
    -o-transform: translateX(-80px);
    transform: translateX(-80px);
  }

  100% {
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px);
  }
}

.Hire-box .hire-animation .hire-shape-3 {
  position: absolute;
  right: 8%;
  top: -10%;
  width: 100px;
  animation: tptranslateY 3s ease-in-out 1s forwards infinite alternate;
}

@keyframes tptranslateY {
  0% {
    -webkit-transform: translateY(-50px);
    -moz-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    -o-transform: translateY(-50px);
    transform: translateY(-50px);
  }

  100% {
    -webkit-transform: translateY(100px);
    -moz-transform: translateY(100px);
    -ms-transform: translateY(100px);
    -o-transform: translateY(100px);
    transform: translateY(100px);
  }
}

.Hire-box .hire-animation .hire-shape-4 {
  position: absolute;
  right: 5%;
  bottom: 10%;
  width: 90px;
  height: auto;
  animation: tptranslateX 3s ease-in-out 1s forwards infinite alternate;
}

@media (max-width: 1100px) {
  .Hire-box {
    padding-block: 100px;
  }
}

@media (max-width: 800px) {
  .Hire-box {
    padding-block: 100px;
  }

  .Hire-box .hire-container h1 {
    font-size: 50px;
  }
}

@media (max-width: 600px) {
  .Hire-box .hire-container h1 {
    font-size: 22px;
    line-height: normal;
    margin-bottom: 20px;
  }
  .Hire-box .hire-animation .hire-shape-1 {
    width: 250px;
  }
  .Hire-box .hire-animation .hire-shape-2,
  .Hire-box .hire-animation .hire-shape-3,
  .Hire-box .hire-animation .hire-shape-4 {
    display: none;
  }
  .Hire-box {
    padding-block: 50px;
  }
}
