.footer-box {
  background: var(--black);
  padding-block: 15px;
}

.copyright-text {
  color: var(--secondarytext2);
  margin: 0px;
  font-weight: 400;
  cursor: default;
}

.copyright-text strong {
  color: var(--primary);
}

.social-row {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.social-row .nav-link {
  width: 40px;
  height: 40px;
  display: block;
  padding: 10px;
  background: #ffffff20;
  color: var(--white) !important;
  transition: all 0.8s;
}

.social-row .nav-link:hover {
  background: var(--primary);
  transform: rotate(360deg);
  transition: all 0.8s;
}
